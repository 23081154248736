.product-content {
  width: inherit;
  padding: calc(90px + 4vh) 0;
}

.product-content:last-child {
  padding-bottom: 10rem;
}

.product-title-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.product-section-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.product-img {
  width: 60%;
  /* min-height: 27vw; */
  background-color: #6F6F6F;
  border-radius: 20px;
  text-align: center;
}

.product-title,
.product-intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.product-title {
  letter-spacing: 0.2rem;
  text-decoration: underline;
  margin-top: 5%;
  font-family: "HelveticaNeueBold";
  color: #707070;
}

.product-intro {
  letter-spacing: 0.15rem;
  line-height: 1.75;
  font-family: "HelveticaNeueMedium";
  color: #707070;
}

.chilli-icon {
  margin-right: 0.5rem;
  width: 2rem;
}

.product-title-section,
.feature-section {
  /* margin: 22vh 20% 13vh; */
}

.customer-card-list {
  margin: 0 7.5vw 10vh;
}

.feature-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fire-icon {
  width: 1.2rem;
  margin-top: 0.75rem;
  justify-self: end;
}

.feature-list .customer-title {
  letter-spacing: 0.15rem !important;
}

.feature-subtitle {
  text-decoration: underline;
  letter-spacing: 0.1rem;
}

.feature-content {
  display: grid;
  grid-template-columns: 5% 92%;
  grid-gap: 3%;
  grid-template-rows: 1fr;
  /* justify-items: end; */
  margin: 0 0 3vh;
}

.feature-description {
  letter-spacing: 0.1rem;
  line-height: 1.75;
  font-family: "HelveticaNeueMedium";
  color: #707070;
}

.features-description-title {
  color:#F4BB38 !important
}

.navigation-more-link {
  width: 100%;
  text-align: end;
  flex: 3;
}

.navigation-faq-link {
  width: 100%;
  text-align: end;
  flex: 7;
}

.navigation-btn {
  border: 1px solid #B7B7B7;
  background-color: transparent;
  padding: 1rem 2rem;
  border-radius: 26px;
  letter-spacing: 0.1rem;
  color: #B7B7B7;
  font-family: "HelveticaNeueRegular";
}

.navigation-btn:hover {
  background-color: #6A6A6A;
  color: #fff;
  border: 1px solid #6A6A6A;
}

.navigation-btn,
.navigation-btn:focus {
  outline: none;
}

.imagelist {
  display: flex;
  flex-direction: row;
  min-height: 45vh;
  align-items: center;
  margin-bottom: 10vh;
}

.imagelist-text-section {
  width: 20vw;
  padding: 0 4% 0;
  text-align: end;
}

.imagelist-title {
  letter-spacing: 0.2rem;
}

.imagelist-description {
  letter-spacing: 0.1rem;
  line-height: 1.75;
}

.imagelist-image-section {
  width: 80vw;
  height: 40vh;
  display: flex;
  flex-direction: row;
}

.imagelist-image:nth-child(2n-1) {
  margin: 0 2% 0 0;
}

.imagelist-image {
  /* width: 49%; */
  height: auto;
  border-radius: 50px;
}

@media(max-width: 1300px) {
  .fire-icon {
    width: 1rem;
  }

  .navigation-faq-link {
    flex: 6
  }

  .navigation-more-link {
    flex: 4
  }
}

@media(max-width: 1050px) {

  .chilli-icon {
    width: 2rem;
  }

  .fire-icon {
    width: 0.8rem;
    margin-top: 0.5rem;
  }

  .navigation-btn {
    padding: 0.75rem 1.5rem;
  }
}

@media(max-width: 767px) {
  .product-content:last-child {
    padding-bottom: 9rem;
  }

  .product-img {
    width: 80%;
  }

  .product-title {
    letter-spacing: 0.15rem;
  }

  .chilli-icon {
    width: 1.5rem;
  }

  .fire-icon {
    width: 0.6rem;
    margin-top: 0.1rem;
  }

  .feature-content {
    margin: 0 0 2.5vh;
  }

  .feature-description {
    letter-spacing: 0.05rem;
  }

  .navigation-btn {
    padding: 0.6rem 1.2rem;
  }

  .navigation-faq-link,
  .navigation-more-link {
    flex: 5
  }
}

@media(max-width: 480px) {

  .product-content {
    width: inherit;
    padding: calc(90px + 2vh) 0;
  }

  /* .product-content:first-child {
    width: inherit;
    padding: calc(90px - 2vh) 0;
  } */

  .product-img {
    width: 100%;
  }

  .product-title {
    letter-spacing: 0.1rem;
  }

  .product-intro,
  .feature-description {
    letter-spacing: 0.05rem;
    padding: 0 1rem;
  }

  .chilli-icon {
    width: 1rem;
  }

  .fire-icon {
    width: 0.4rem;
  }

  .feature-content {
    margin: 0 0 2vh;
  }

  .navigation-btn {
    padding: 0.5rem 1rem;
  }
}

@media(max-width: 300px) {
  .product-img {
    padding-left: 15vw;
    width: 55vw;
  }

  .chilli-icon {
    width: 2rem;
  }

  .product-title {
    width: min-content;
  }
}