.carousel.carousel-slider {
  margin: auto;
}
.carousel .slider-wrapper {
  border-radius: 25px;
}
.carousel .slide img {
  margin-bottom: 3rem;
  border-radius: 25px;
}
.carousel .control-dots .dot {
  width: 12px;
  height: 12px;
}
.carousel .control-dots .dot:focus,
.arrow-btn:focus {
  outline: none !important;
}
.arrow-btn {
  z-index: 2;
  position: absolute;
  top: 0;
  width: 10%;
  height: calc(100% - 3rem);
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
}
.arrow-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.arrow-right-btn {
  right: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}  
.arrow-left-btn{
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.arrow-icon{
  color:rgba(255, 255, 255, 0.3);
}
@media(max-width: 767px) {
  .arrow-btn {
    width: 15%;
  }
}