:root {
  --pdf-title-size: 1.5rem;
  --pdf-subtitle-size: 1.3rem;
  --pdf-description-size: 1.1rem;
}

.product-table-section {
    display: flex;
    flex-direction: column;
    width: 75vw;
    max-width: 90vw;
    justify-content: center;
    align-items: center;
}

.product-table-title {
    width: 100%;
    height: 6vw;
    display: inherit;
    justify-content: center;
    align-items: center;
    border-color: #F4BB38;
    border-width: 1px;
    border-style: solid;
    font-size: var(--pdf-title-size);
}

.product-table-content {
    width: inherit;
    display: flex;
    flex-direction: row;
    min-height: 6vw;
    justify-content: center;
    align-items: center;
}

.product-table-subtitle {
    flex: 3;
    min-height: 6vw;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid #F4BB38;
}

.product-table-content.no-description{
    width: inherit;
    min-height: 6vw;
    max-height: 6vw;
    border: 0.5px solid #F4BB38;
    display: inherit;
    justify-content: center;
    align-items: center;
    color: #F4BB38;
}

.product-description-section {
    flex: 7;
    min-height: 6vw;
    max-height: 6vw;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border: 0.5px solid #F4BB38;
}

.product-link {
    color: deepskyblue;
    white-space: pre-line;
    text-decoration: underline;
}

.product-description{
    color: #B7B7B7;
    white-space: pre-line;
}

.pdf-fontsize-xl {
    font-size: var(--pdf-subtitle-size);
}

.pdf-fontsize-l {
    font-size: var(--pdf-description-size);
}

@media(max-width: 1600px) {
  .pdf-fontsize-xl {
    font-size: calc(var(--pdf-subtitle-size) * 0.95);
  }
  .pdf-fontsize-l {
      font-size: calc(var(--pdf-description-size) * 0.95);
  }
  .product-table-content,
  .product-table-subtitle,
  .product-table-content.no-description,
  .product-description-section {
    min-height: 9.5vw;
    max-height: 9.5vw;
  }
}

@media(max-width: 1300px) {
  .pdf-fontsize-xl {
    font-size: calc(var(--pdf-subtitle-size) * 0.9);
  }
  .pdf-fontsize-l {
      font-size: calc(var(--pdf-description-size) * 0.85);
  }
  .product-table-content,
  .product-table-subtitle,
  .product-table-content.no-description,
  .product-description-section {
    min-height: 11vw;
    max-height: 11vw;
  }
}

@media(max-width: 960px) {
  .pdf-fontsize-xl {
    font-size: calc(var(--pdf-subtitle-size) * 0.95);
    letter-spacing: 0.1rem;
  }
  .pdf-fontsize-l {
      font-size: calc(var(--pdf-description-size) * 0.8);
      letter-spacing: 0.1rem;
  }
  .product-table-content,
  .product-table-subtitle,
  .product-table-content.no-description,
  .product-description-section {
    min-height: 13vw;
    max-height: 13vw;
  }
  .product-table-section {
    width: 90vw;
  }
}

.product-table-mobile {
  display: none;
}
.product-table-web {
  display: flex;
}

@media(max-width: 767px) {
  .product-table-mobile {
    display: flex;
  }
  .product-table-web {
    display: none;
  }
  .product-table-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .product-table-content.no-description,
  .product-description-section
  {
    width: 85vw;
    min-height: 20vw;
  }
  .product-table-title,
  .product-table-subtitle
  {
    width: 85vw;
    min-height: 6vw;
    max-height: 6vw;
    border-color: #F4BB38;
    border-width: 1px;
    border-style: solid;
    font-size: calc(var(--pdf-title-size) * 0.8);
  }
  .product-table-content {
    min-height: initial;
    max-height: initial;
    width: 85vw;
    flex-direction: column;;
  }
  .pdf-fontsize-xl {
    font-size: calc(var(--pdf-subtitle-size) * 0.9);
    letter-spacing: 0.1rem;
  }
  .pdf-fontsize-l {
      font-size: calc(var(--pdf-description-size) * 0.9);
      letter-spacing: 0.1rem;
  }
}

@media(max-width: 480px) {
  .product-table-mobile {
    display: flex;
  }
  .product-table-web {
    display: none;
  }
  .product-table-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .product-table-content.no-description,
  .product-description-section
  {
    width: 90vw;
    min-height: 30vw;
    max-height: 30vw;
  }
  .product-table-title,
  .product-table-subtitle
  {
    width: 90vw;
    min-height: 12vw;
    max-height: 12vw;
    font-size: calc(var(--pdf-title-size) * 0.6);
    letter-spacing: 0.05rem;
  }
  .product-table-content {
    min-height: initial;
    max-height: initial;
    width: 90vw;
    flex-direction: column;;
  }
  .pdf-fontsize-xl {
    font-size: calc(var(--pdf-subtitle-size) * 0.8);
    letter-spacing: 0.05rem;
  }
  .pdf-fontsize-l {
      font-size: calc(var(--pdf-description-size) * 0.8);
      letter-spacing: 0.05rem;
  }
}

