.description-card {
  background-color: #3B3F48;
  display: grid;
  height: auto;
  min-height: 20vh;
  grid-gap: 3rem 3rem;
  border-radius: 15px;
  align-items: center;
  padding: 3.5rem 5vw;
}

.description-card:last-child {
  margin-bottom: 0;
}

.grid-even {
  justify-items: left;
  grid-template-columns: calc(10% - 4rem) calc(50% - 0rem) calc(40% - 2rem);
  grid-template-rows: 4rem calc(100% - 7rem);
}

.grid-odd {
  justify-items: right;
  grid-template-columns: calc(40% - 2rem) calc(50% - 0rem) calc(10% - 4rem);
  grid-template-rows: 4rem calc(100% - 7rem);
}

.grid-title-even {
  grid-column: 2/4;
  grid-row: 1/2;
}

.grid-title-odd {
  grid-column: 1/3;
  grid-row: 1/2;
}

.grid-icon-even {
  grid-column: 1/2;
  grid-row: 1/2;
}

.grid-icon-odd {
  grid-column: 3/4;
  grid-row: 1/2;
}

.grid-content-even {
  grid-column: 1/3;
  grid-row: 2/3;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-content-odd {
  grid-column: 2/4;
  grid-row: 2/3;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-image-even {
  grid-column: 3/4;
  justify-self: center;
  grid-row: 1/3;
}

.grid-image-odd {
  grid-column: 1/2;
  justify-self: center;
  grid-row: 1/3;
}

.grid-image-mobile {
  grid-row: 2/3 !important;
}

.description-title {
  letter-spacing: 0.2rem;
  font-family: "HelveticaNeueBold";
  color: #fff;
}

.description-icon {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  place-self: left;
}

.description-img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.description-content {
  line-height: 1.75;
  letter-spacing: 0.15rem;
  font-family: "HelveticaNeueRegular";
  color: #B7B7B7;
  max-height: auto;
  display: flex;
  flex-direction: row;
}

.content-ellipsis {
  max-height: 9rem;
  overflow: hidden;
}

.content-text {
  flex: 8;
  line-height: 1.75;
  letter-spacing: 0.1rem;
}

.read-more-btn {
  flex: 1;
  height: 9rem;
  display: flex;
  align-items: flex-end;
}

.description-more {
  display: flex;
}

.description-more-odd {
  justify-content: flex-start;
}

.description-more-even {
  justify-content: flex-end;
}

.learn-more-navigation {
  display: flex;
  justify-content: flex-end;
}

.learn-more-link {
  width: 100%;
  text-align: end;
}

.learn-more-btn {
  border: 1px solid #B7B7B7;
  background-color: transparent;
  padding: 2% 4%;
  border-radius: 26px;
  letter-spacing: 0.1rem;
  margin: 5% 0;
  font-family: "HelveticaNeueMedium";
  color: #B7B7B7;
}

.learn-more-btn,
.learn-more-btn:focus {
  outline: none;
}

.learn-more-btn:hover {
  background-color: #6A6A6A;
  border-color: #6A6A6A;
  color: #fff;
  font-family: "HelveticaNeueMedium";
}

@media(max-width : 1600px) {
  .description-card {
    gap: 2rem 2rem;
  }

  .grid-even {
    grid-template-columns: calc(10% - 2rem) calc(50% - 0rem) calc(40% - 2rem);
    grid-template-rows: 4rem calc(100% - 6rem);
  }

  .grid-odd {
    grid-template-columns: calc(40% - 2rem) calc(50% - 0rem) calc(10% - 2rem);
    grid-template-rows: 4rem calc(100% - 6rem);
  }

  .content-ellipsis {
    max-height: 10rem;
  }

  .read-more-btn {
    height: 10rem;
  }
}

@media(max-width : 1300px) {
  .description-card {
    padding: 4vh 5vw;
    gap: 2rem 2rem;
  }

  .description-title {
    letter-spacing: 0.2rem;
  }

  .description-content {
    letter-spacing: 0.05rem;
  }

  .grid-even {
    grid-template-columns: calc(10% - 2rem) calc(50% - 0rem) calc(40% - 2rem);
    grid-template-rows: 4rem calc(100% - 6rem);
  }

  .grid-odd {
    grid-template-columns: calc(40% - 2rem) calc(50% - 0rem) calc(10% - 2rem);
    grid-template-rows: 4rem calc(100% - 6rem);
  }

  .content-ellipsis {
    max-height: 10rem;
  }

  .read-more-btn {
    height: 10rem;
  }
}

@media(max-width : 1050px) {
  .description-card {
    padding: 4vh 5vw;
    gap: 1rem 2rem;
  }

  .description-title {
    letter-spacing: 0.2rem;
  }

  .description-content {
    letter-spacing: 0.05rem;
  }

  .grid-even {
    grid-template-columns: calc(15% - 3rem) calc(45% - 0rem) calc(40% - 1rem);
    grid-template-rows: 3rem calc(100% - 4rem);
  }

  .grid-odd {
    grid-template-columns: calc(40% - 1rem) calc(45% - 0rem) calc(15% - 3rem);
    grid-template-rows: 3rem calc(100% - 4rem);
  }

  .content-ellipsis {
    max-height: 7rem;
  }

  .read-more-btn {
    height: 8rem;
  }

}

@media(max-width : 767px) {
  .description-card {
    padding: 3vh 5vw;
    gap: 1rem 1rem;
  }

  .description-title {
    letter-spacing: 0.1rem;
  }

  .description-content {
    letter-spacing: 0.05rem;
  }

  .content-ellipsis {
    max-height: 6rem;
  }

  .read-more-btn {
    height: 6rem;
  }

  .grid-even {
    grid-template-columns: calc(15% - 1rem) calc(45% - 0rem) calc(40% - 1rem);
    grid-template-rows: 2rem calc(100% - 3rem);
  }

  .grid-odd {
    grid-template-columns: calc(40% - 1rem) calc(45% - 0rem) calc(15% - 1rem);
    grid-template-rows: 2rem calc(100% - 3rem);
  }

  .content-text {
    line-height: 1.5;
    letter-spacing: 0.05rem;
  }

}

@media(max-width : 480px) {
  .description-card {
    padding: 2vh 5vw;
    grid-gap: 1rem 1rem;
    border-radius: 8px;
  }

  .description-title {
    letter-spacing: 0.08rem;
  }

  .description-content {
    letter-spacing: 0.01rem;
  }

  .content-ellipsis {
    max-height: 4.75rem;
  }

  .read-more-btn {
    height: 4.75rem;
  }

  .grid-even {
    grid-template-columns: calc(15% - 1rem) calc(50% - 0rem) calc(35% - 1rem);
    grid-template-rows: 2rem calc(100% - 3rem);
  }

  .grid-odd {
    grid-template-columns: calc(35% - 1rem) calc(50% - 0rem) calc(15% - 1rem);
    grid-template-rows: 2rem calc(100% - 3rem);
  }

  .grid-title-even.grid-title-mobile {
    grid-column: 2/4;
  }

  .grid-title-odd.grid-title-mobile {
    grid-column: 1/3;
  }

  .grid-image-mobile {
    place-self: baseline;
  }

  .description-img {
    max-width: 120%;
  }

  .content-text {
    line-height: 1.5;
    letter-spacing: 0.05rem;
  }
}