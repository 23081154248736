.home-background {
  /* position: absolute; */
  box-sizing: border-box;
  padding: 90px 0;
  min-height: 100%;
  width: 100%;
  background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
}

.home-content {
  min-height: 100vh;
  padding: 10vh 10%;
  /* padding-bottom: 5vh; */
  /* position: relative; */
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video;
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  -ms-transition: opacity .3s ease-out;
  -o-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out;
}

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table;
}

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform .3s ease-out;
  -moz-transition: -moz-transform .3s ease-out;
  -ms-transition: -ms-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.client-card-list,
.introduction {
  margin: 0 7.5% 8%;
}

.banner {
  margin: 0 0 5%;
  position: relative;
}

.banner-img {
  border-radius: 20px;
  width: 100%;
  height: auto;
}

.banner-img-mobile {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.player-btn {
  border: 1px solid #B51A29;
  border-radius: 16px;
  padding: 0.5rem 1rem;
  background-color: transparent;
  outline: none;
  align-items: center;
  z-index: 3;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 3%;
  letter-spacing: 0.1rem;
  font-family: "HelveticaNeueLight";
  color: #fff;
}

.player-btn:hover {
  background-color: #B51A29;
  color: #fff;
}

.player-icon {
  color: #B51A29;
}

.player-btn:hover .player-icon {
  color: #fff;
}

.device-title {
  letter-spacing: 0.3rem;
  border-bottom: 1px solid #FFFFFF;
  display: inline-block;
  line-height: 0.85;
  margin: 0 1% 3%;
  font-family: "HelveticaNeueBold";
  color: #fff;
}

.description-card-list,
.device-list {
  margin: 0 0 10%;
}

.device-list:last-child {
  margin: 0;
}

.device-list-slider {
  align-items: center;
  height: auto;
  display: grid;
  grid-template-columns: 5% 85% 5%;
  grid-gap: 2.5%;
}

.device-slide-btn {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-color: transparent;
  display: flex;
  align-items: center;
}


.device-slide-btn:focus {
  outline: transparent !important;
}

.device-slide-btn>.arrow-icon {
  color: #FFFFFF80;
}

.device-slide-btn:hover>.arrow-icon {
  color: #FFFFFF;
}

.device-card-list {
  width: 100%;
  /* padding-bottom: 1rem; */
  display: flex;
  flex-direction: row;
  gap: 2rem;
  overflow-x: hidden;
}

.client-card-list {
  justify-items: center;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.description-card-list {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.introduction {
  /* padding: 0 10%; */
  font-family: "HelveticaNeueLight";
  color: #fff;
}

.introduction-emphasis {
  font-family: "HelveticaNeueBold";
}

.intro-section {
  line-height: 1.5;
  letter-spacing: 0.2rem;
  margin-bottom: 1rem;
  text-align: left;
}

@media(max-width: 1024px) {
  .home-content {
    padding: 2vh 8%;
  }
}

@media(max-width: 767px) {
  .home-content {
    padding: 2vh 5%;
  }

  .device-title {
    letter-spacing: 1px;
    overflow-wrap: anywhere;
  }


  .player-btn {
    border-radius: 7px;
    padding: 1% 1%;
    gap: 0.3rem;
  }

  .banner,
  .description-card-list,
  .device-list {
    margin: 0 6% 8%;
  }
  .banner-img-mobile {
    display: block;
  }
  .banner-img {
    display: none;
  }
  .device-list {
    margin: 0 0 4rem;
  }

  .introduction {
    padding: 0 5%;
  }


  .device-card-list {
    overflow-x: auto;
    gap: 1.5rem;
  }

  /* .device-slide-btn {
    display: none;
  } */

  .device-title {
    letter-spacing: 0.1rem;
    margin: 1rem 0;
  }
}

@media(max-width: 480px) {
  .home-content {   
    padding: 2vh 13px;
  }

  .player-btn {
    padding: 3% 2%;
    letter-spacing: 0.05rem;
  }

  .intro-section {
    letter-spacing: 0.1rem;
  }

  .device-list-slider {
    display: flex;
    flex-direction: row;
    grid-template-columns: none;
    grid-gap: 0;
  }

  .introduction {
    padding: 0;
    margin: 6vh 7.5%;
  }

  
  .description-card-list {
    margin: 0 0 4vh;
    gap: 3vh;
  }
  .banner,
  .device-list {
    margin: 0;
  }

  .device-slide-btn {
    display: none;
  }

  .device-card-list {
    gap: 0.5rem;
    margin: 0 0 5rem;
  }

  .device-title {
    letter-spacing: 0.1rem;
    margin: 1.5rem 0 1.5rem;
  }
}
@media(min-width:767px) {
  .banner-img-mobile {
    display: none;
  }
  .banner-img {
    display: block;
  }
}