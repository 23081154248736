.support-background {
  max-width: 100vw;
  min-height: 100vh;
  background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
}

.support-title {
  border-bottom-color: #F0B543;
  border-bottom-width: 2px;
  letter-spacing: 0.15rem;
  width: fit-content;
  border-bottom-style: solid;
  margin-bottom: 2rem;
  font-family: "HelveticaNeueRegular";
}

.support-content {
  padding: 90px 0;
  margin: 0 5vw 0;
}

.request-form-web {
  /* display: grid; */
  grid-template-columns: repeat(2, calc(50% - 5vw));
  grid-template-rows: auto auto;
  grid-gap: 0 10vw;
}

.request-form-mobile {
  /* display: grid; */
  grid-template-columns: repeat(2, calc(50% - 5vw));
  grid-template-rows: auto auto;
  grid-gap: 0 10vw;
  margin-bottom: 4rem;
}

.support-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 6rem;
}

.notice-title {
  font-family: "HelveticaNeueBold";
}

.notice-content {
  margin: calc(90px + 4vh) auto 90px;
  width: 50%;
}

.notice-text {
  background-color: #FFFFFFC2;
  color: #21242D;
  border-radius: 22px;
  line-height: 2;
  padding: 2rem 4rem;
  letter-spacing: 0.5px;
  font-family: "HelveticaNeueRegular";
}

.request-hint-text {
  line-height: 1.5;
  margin: 0 0 2rem;
  width: 90%;
  grid-column: 1/3;
  font-family: "HelveticaNeueLight";
  color: #fff;
}

.request-hint-text,
.request-sub-title {
  letter-spacing: 0.05rem;
}

.request-form-list-web {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 1rem));
  grid-gap: 2rem;
}

.left-section .request-form-list-web {
  grid-template-rows: repeat(3, 8rem);
}

.right-section .request-form-list-web {
  grid-template-rows: repeat(2, 8rem);
}

.request-form-list-mobile {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 2.5%;
  grid-template-rows: repeat(5, 18%);
}

.request-form-group,
.request-form-additional {
  display: flex;
  flex-direction: column;
}

.request-form-additional {
  margin: 2rem 0;
}

.request-form-additional-mobile {
  margin: 1rem 0;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
}

.request-form-left-section {
  width: 50%;
}

.request-form-right-section {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0.25rem 0;
}

.request-form-title {
  margin: 0.5rem 0;
  letter-spacing: 0.1rem;
}

.request-form-title,
.request-form-control {
  margin: 0.5rem 0;
  font-family: "HelveticaNeueRegular";
  color: #fff;
}

.request-form-control {
  border-color: #707070 !important;
  border-width: 1px;
  border-radius: 20px;
  border-style: solid;
  height: fit-content;
  outline: none;
  padding: 0.5rem 1rem;
  width: 100%;
}

.invalid-feedback {
  font-family: "HelveticaNeueRegular";
  color: #F0B543;
  font-size: 1rem;
}

input.request-form-control {
  width: calc(100% - 2rem - 2px);
}

.left-section .request-form-control {
  background-color: #2D3037;
}

.right-section .request-form-control {
  background-color: #21242D;
}

.additional-form-control {
  border-color: #707070 !important;
  border-width: 1px;
  border-style: solid;
  min-height: 12vh;
  border-radius: 6px;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0;
  font-family: "HelveticaNeueRegular";
  color: #fff;
  background-color: #21242D;
}

.request-submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
}

.request-submit-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  grid-column: 1/3;
}

.request-submit-btn {
  background-color: #F0B543;
  border-radius: 2rem;
  border-color: transparent;
  box-shadow: 2px 2px #707070;
  padding: 0.5rem 1rem;
  margin: 2rem 0;
  letter-spacing: 0.1rem;
  font-family: "HelveticaNeueRegular";
  color: #fff;
}

.request-submit-btn:hover {
  background-color: #E39C12;
}

.request-submit-btn,
.request-submit-btn:focus {
  outline: none;
}

.contact-section-web {
  /* display: grid; */
  grid-gap: 2%;
  grid-template-columns: 50% 23% 23%;
  grid-template-rows: 1fr;
  align-items: start;
  margin-bottom: 6rem;
}

.contact-section-mobile {
  /* display: grid; */
  grid-gap: 2rem;
  align-items: center;
  margin-bottom: 6rem;
}

.contact-title,
.career-title {
  font-family: "HelveticaNeueRegular";
  letter-spacing: 0.1rem;
}

.contact-content,
.career-content {
  line-height: 1.5;
  letter-spacing: 0.1rem;
  width: 100%;
  font-family: "HelveticaNeueLight";
  color: #fff;
}

.career-email {
  color: #F0B543;
  font-family: "HelveticaNeueRegular";
}

.contact-list {
  width: 100%;
  overflow-wrap: break-word;
}

.list-companies > .contact-list {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.list-others > .contact-list {
  margin-left: 2rem;
  margin-right: 2rem;
}

.list-companies {
  display: flex;
  flex-direction: column;
  
}

.list-others {
  display: flex;
  flex-direction: row;
}

.star-mark {
  color: #B51A29;
  font-family: "HelveticaNeueBold";
}

.modal-background {
  background-color: #0000006A;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 8;
}

.modal-box {
  background-color: #21242D;
  padding: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  position: fixed;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modal-body {
  letter-spacing: 0.15rem;
  font-family: "HelveticaNeueRegular";
  width: fit-content;
  height: fit-content;
  color: #fff;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-btn {
  letter-spacing: 0.15rem;
  font-family: "HelveticaNeueBold";
  width: fit-content;
  height: fit-content;
  color: #fff;
  background-color: #F0B543;
}

@media(max-width: 1300px) {}

@media(max-width: 1050px) {
  .notice-text {
    background-color: #FFFFFFC2;
    color: #21242D;
    border-radius: 22px;
    line-height: 1.5;
    padding: 2rem 3rem;
    letter-spacing: 0.5px;
  }

}

@media(max-width: 767px) {
  .contact-section-mobile {
    display: grid;
  }

  .contact-section-web {
    display: none;
  }

  .request-form-group,
  .request-form-additional {
    height: 8rem;
  }

  .invalid-feedback {
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }

  .request-form-title {
    letter-spacing: 0.05rem;
    flex: 1;
  }

  .input-group {
    flex: 2;
  }

  .notice-content {
    margin: 60px auto;
    width: 85%;
  }

  .notice-text {
    line-height: 1.5;
    padding: 1rem 2rem;
  }


  .request-form-web,
  .request-form-additional,
  .request-submit,
  .request-form-list-web {
    display: none;
  }

  .request-form-mobile,
  .request-form-list-mobile {
    display: grid;
  }

  .request-form-additional-mobile,
  .request-submit-mobile {
    display: flex;
  }

  .contact-section-web {
    grid-gap: 15% 10%;
    grid-template-columns: 45% 45%;
    grid-template-rows: 1fr 1fr;
    align-items: start;
    margin-bottom: 6rem;
  }

  .contact-list:first-child {
    grid-column: 1/3;
  }
    
  .list-others > .contact-list {
    margin: 1rem 0 1rem 0;
  }
  
  .list-others {
    flex-direction: column;
  }
}

@media(max-width: 480px) {
  .support-content {
    padding: 90px 0 0;
    margin: 0 5vw 0;
  }

  .support-title {
    margin-bottom: 1rem;
  }

  .notice-content {
    width: 100%;
  }

  .notice-text {
    padding: 1rem 1.5rem;
  }

  .invalid-feedback {
    font-size: 0.8rem;
  }

  .request-form-group,
  .request-form-additional {
    height: 6rem;
  }

  .request-form-title,
  .request-form-control {
    margin: 0.25rem 0;
  }

  .request-form-additional {
    margin: 1rem 0;
  }

  .request-request-btn {
    margin: 0 0 1rem;
  }

  .contact-section-mobile {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 0.75fr 0.75fr;
    align-items: center;
    margin-bottom: 6rem;
  }

  .contact-list:first-child {
    grid-column: 1/2;
  }
  
  .list-others > .contact-list {
    margin: 1rem 0 1rem 0;
  }
  
  .list-others {
    flex-direction: column;
  }
}

@media(min-width: 767px) {
  .contact-section-web {
    display: none;
  }

  .request-form-web,
  .request-form-list-web,
  .contact-section-web {
    display: grid;
  }

  .request-form-additional,
  .request-submit {
    display: flex;
  }

  .request-form-mobile,
  .request-form-additional-mobile,
  .request-submit-mobile,
  .request-form-list-mobile,
  .contact-section-mobile {
    display: none;
  }
}