.device-card {
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5%;
}

.device-card-mobile {
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.device-card:focus,
.device-card-mobile:focus,
.device-img-btn:focus {
  outline: none;
}

.device-img-btn {
  background-color: #3B3F48;
  width: 100%;
  height: inherit;
  position: relative;
  border-width: 0;
  border-radius: 27px;
  border-color: transparent;
}

.device-img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  top: 50%;
  height: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}

.device-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 27px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s linear, color 0.4s ease;
  font-family: "HelveticaNeueBold";
  color: transparent;
}

.device-img-btn:hover .device-overlay {
  background-color: rgba(42, 45, 52, 0.5);
  cursor: pointer;
  color: #FFFFFF;
}

.device-card-title {
  letter-spacing: 0.1rem;
  overflow-wrap: anywhere;
}

.device-card-title-mobile {
  padding: 0 3px;
  font-family: "HelveticaNeueMedium";
  color: #FFFFFF;
}

.device-content {
  margin: 10%;
  line-height: 1.5;
  letter-spacing: 0.05rem;
}

@media (min-width: 1400px) {
  .device-content {
    letter-spacing: 0.15rem;
    line-height: 1.75;
  }
}

@media (min-width: 767px) {
  .device-card {
    display: flex;
  }

  .device-card-mobile {
    display: none;
  }

  .device-img-btn {
    width: 20vw;
    height: 20vw;
  }

  .device-pos-btn {
    width: 21vw;
    height: 21vw;
  }
}

@media (max-width: 767px) {
  .device-card {
    display: flex;
  }

  .device-card-mobile {
    display: none;
  }
  .device-img-btn {
    width: 115px;
    height: 115px;
  }
  .device-overlay,
  .device-img-btn {
    border-radius: 8px;
  }
  .device-pos-btn {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 480px) {
  .device-card {
    display: none;
  }

  .device-card-mobile {
    display: flex;
    margin-bottom: 1rem;
  }

  .device-img-btn {
    width: 106px;
    height: 106px;
  }

  .device-pos-btn {
    width: 114px;
    height: 114px;
  }
}