.client-card {
  min-height: 10vh;
  min-width: 20%;
  width: max-content;
  border-radius: 15px;
  padding: 2%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.client-circle {
  border-radius: 50%;
  padding: 1vw;
  border: #F0B543 2px solid;
}

.client-icon {
  color: #F0B543;
}

.client-title {
  margin: 1rem 0;
  letter-spacing: 0.1rem;
  font-family: "HelveticaNeueBold";
  color: #fff;
}

.client-content {
  /* margin: 10%; */
  /* text-align: justify; */
  line-height: 1.5;
  letter-spacing: 0.05rem;
  font-family: "HelveticaNeueMedium";
  color: #707070;
}

@media(max-width: 1300px) {
  .client-circle {
    padding: 1.25vw;
  }
}

@media(max-width: 1050px) {
  .client-circle {
    padding: 1.5vw;
  }
}

@media(max-width: 767px) {
  .client-circle {
    padding: 1.75vw;
  }
}

@media(max-width: 480px) {
  .client-circle {
    padding: 2vw;
  }
}