.nav-background {
  position: fixed;
  z-index: 5;
  width: 100%;
  outline: none;
  top: 0;
  left: 0;
  background-color: #2A2D34;
}

.nav-background:focus-visible {
  outline: none;
}

.nav-bar {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  z-index: 4;
  padding: 0 50px;
  box-sizing: border-box;
}

.nav-bar-mobile {
  width: 100vw;
  height: 90px;
  display: flex;
  flex-direction: row;
  z-index: 4;
  justify-content: space-between;
}

.hotsauce-logo {
  height: 45px;
}

.mobile-nav-btn {
  height: 100%;
  background-color: transparent;
  border: none;
  width: 3rem;
}

.mobile-nav-btn:focus,
.mobile-nav-btn:focus-visible {
  outline: none;
}

.mobile-menu-btn-group {
  width: 100vw;
  height: auto;
  /* padding: 1rem 0; */
  display: flex;
  flex-direction: column;
  background-color: #292C34;
  border-radius: 0 0 16px 16px;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-name: mobile-menu-open;
  animation-iteration-count: 1;
}

.mobile-menu-btn-group a:focus-visible,
.mobile-menu-btn:focus-visible {
  outline: none;
  border: none;
  outline-offset: 0;
}

.mobile-menu-btn {
  width: 100%;
  padding: 1.25rem 2rem;
  background-color: transparent;
  border: none;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-name: mobile-menu-btn-slidein;
  animation-iteration-count: 1;
}


.mobile-menu-btn:hover {
  background-color: #B51A29;
  border: none;
  color: #fff;
}

.mobile-menu-name {
  font-family: "HelveticaNeueRegular";
  color: #fff;
  text-align: left;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-name: mobile-menu-btn-name-slidein;
  animation-iteration-count: 1;
}


.sub-nav-open-icon {
  width: 1rem;
  height: 100%;
}

.mobile-home-btn {
  width: auto;
  padding: 0;
}

.mobile-home-btn .nav-logo {
  width: auto;
  height: 100%;
}

.menu-icon {
  stroke: #fff;
  fill: #fff;
  width: 1rem;
  height: 1rem;
  animation: rotate-icon ease-in-out 0.05s forwards;
}
@keyframes rotate-icon {
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(180deg);
  }
}
.nav-btn-group {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-btn,
.sub-nav-btn {
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
}

.nav-btn {
  height: 50%;
  font-family: "HelveticaNeueRegular";
  color: #fff;
  letter-spacing: 1.1px;

  border: transparent solid 2px;
}

.nav-btn:hover {
  background-color: transparent;
  animation: 0.5s ease-out btn-title-hover forwards;
}

.nav-btn-click,
.nav-btn:focus-visible {
  background-color: #B51A29;
  border-color: transparent;
}

.nav-btn:focus,
.sub-nav-btn:focus,
.sub-nav-btn-title:focus {
  outline: none !important;
}

.mainpage-btn {
  background-color: transparent;
  border-color: transparent !important;
  outline: none !important;
}

.nav-logo {
  width: 80%;
  height: 100%;
  margin: auto;
  display: block;
}

.hotsauce-logo a {
  height: inherit;
}

.logo-btn {
  background-color: transparent;
  outline: none !important;
  padding: 0;
  margin: auto;
  border: none;
  align-items: center;
  display: flex;
  height: 100%;
}

.sub-nav-background {
  background-color: #1F2127;
  width: 100%;
  z-index: 3;
  opacity: 0.85;
  padding: 0 50px;
  box-sizing: border-box;
  height: 90px;

}

.sub-nav-background .click {
  animation: sub-nav-bg-slidein 0.1s ease-out forwards;
}

.mobile-sub-nav-background {
  background-color: #1F2127;
  width: 100vw;
  height: fit-content;
  z-index: 3;

}

.home-hint {
  text-align: center;
  margin: 22px 0 0 0;
  background-color: #2A2D34;
  border: solid 1px #707070;
  border-radius: 6px;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  z-index: 5;
  font-family: "HelveticaNeueRegular";
  color: #fff;
}

@keyframes sub-nav-bg-slidein {
  from {
    height: 0;
  }

  to {
    height: 90px;
  }
}

@keyframes mobile-sub-nav-open {
  from {
    height: 0;
  }

  to {
    height: counter(mobile-menu-btn);
  }
}

.mobile-sub-nav-btn-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: mobile-sub-nav-open 0.1s ease-in-out forwards;

}

.sub-nav-btn-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}

.sub-nav-btn {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.5rem;
  letter-spacing: 2.2px;
  width: fit-content;
  color: #B7B7B7;
  border-color: transparent;
  font-family: "HelveticaNeueRegular";
}


.sub-nav-btn:hover {
  background-color: transparent;
  border: #F0B543 solid 3px;
  border-radius: 25px;
  font-family: "HelveticaNeueMedium";

}

@keyframes btn-title-hover {
  from {
    letter-spacing: 1.1px;
    border-color: transparent;
  }

  to {
    letter-spacing: 2.2px;
    border-color: #B51A29;
  }
}

@media(max-width: 1299px) {
  .nav-bar {
    padding: 0 20px;
  }

  .nav-btn-group {
    gap: 1rem;
  }
}

@media(max-width: 999px) {
  .nav-bar {
    padding: 0 15px;

  }

  .hotsauce-logo {
    height: 40px;
  }

  .nav-logo {
    width: 80%;
  }

  .nav-btn-group {
    gap: 0.5rem;
  }


  .nav-btn,
  .sub-nav-btn {
    padding: 0.3rem 0.5rem;
  }

  .nav-btn:hover {
    animation: 0.25s ease-out content-hover forwards;
  }
}

@keyframes content-hover {
  from {
    letter-spacing: 1px;
    border-color: transparent;
  }

  to {
    letter-spacing: 1.5px;
    border-color: #B51A29;
  }
}

@media(max-width: 767px) {

  .nav-bar,
  .sub-nav-background {
    display: none;
  }

  .nav-bar-mobile {
    display: flex;
    padding: 0 2rem;
    box-sizing: border-box;
  }

  .mobile-sub-nav-background {
    display: block;
  }

  .mobile-menu-btn-group {
    display: flex;
  }

  .mobile-home-btn .nav-logo {
    width: 165px;
    height: auto;
  }

  .hotsauce-logo {
    height: 35px;
  }

  .nav-logo {
    width: 70%;
  }

  .nav-btn,
  .sub-nav-btn {
    border-radius: 20px;
    letter-spacing: 1.1px;
    padding: 0.25rem 0.5rem;
  }

  .nav-btn {
    height: 40%;
  }

  .home-hint {
    margin: 2rem auto 0;
  }
}

@media(max-width: 480px) {
  .hotsauce-logo {
    height: 35px;
  }
}

@media(min-width: 767px) {
  .nav-bar {
    display: flex;
  }

  .sub-nav-background {
    display: block;
  }

  .nav-bar-mobile,
  .mobile-menu-btn-group,
  .mobile-sub-nav-background {
    display: none;
  }

}