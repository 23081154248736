.footer-background {
  background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
  min-height: fit-content;
  width: 100%;
  height: 128px;
}

.footer-background-mobile {
  background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
  width: 100%;
}

.dividing-line {
  border-color: #D2D2D2;
  border-width: 1px;
  margin: 0 2%;
}

.footer-content {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.footer-content-mobile {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.footer-left,
.footer-right {
  flex: 1;
  display: flex;
  padding: 1rem 4rem;
  box-sizing: border-box;
  height: inherit;
}

.footer-content-mobile .footer-left,
.footer-content-mobile .footer-right {
  display: block;
}

.footer-left {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.footer-right {
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

}

.footer-content-mobile .footer-left-content,
.footer-content-mobile .footer-right-content {
  padding: 1% 5%;
}

.company-name {
  margin: 0;
  font-family: "HelveticaNeueRegular";
  color: #B51A29;
}

.company-info,
.copyright {
  margin: 0.25rem 0;
  font-family: "HelveticaNeueLight";
  color: #707070;
}

@media(max-width: 1300px) {
  .footer-background {
    height: 8rem;
  }
}

@media(max-width: 1050px) {
  .footer-background {
    height: 8rem;
  }
}

@media(max-width: 767px) {

  /* .footer-background {
    height: 8rem;
  } */
  .footer-content,
  .footer-background {
    display: none;
  }

  .footer-background-mobile {
    display: block;
  }

  .footer-content-mobile {
    display: flex;
  }

  /* .footer-content {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
  } */

  .footer-left {
    padding: 0rem;
  }

  .footer-right {
    padding: 0rem;
  }
}

@media(max-width: 480px) {
  .footer-background {
    height: fit-content;
  }

  .footer-content-mobile {
    padding: 1rem;
  }

  .footer-left,
  .footer-right {
    padding: 0;
  }

  .footer-right {
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 0.25rem;
  }
}

@media(min-width: 767px) {

  .footer-content-mobile,
  .footer-background-mobile {
    display: none;
  }

  .footer-background {
    display: block;
  }

  .footer-content {
    display: flex;
  }

  .footer-left,
  .footer-right {
    padding: 1rem 4rem;
  }
}