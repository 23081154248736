.product-background {
    max-width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 90px 0;
    box-sizing: border-box;
    background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
  }
  
  .product-background::-webkit-scrollbar {
    display: none;
  }
  
  .product-list {
    min-height: fit-content;
    flex-direction: row;
    display: flex;
    padding: 0 22%;
    box-sizing: border-box;
  }
  
  .customer-card-list {
    margin: 0 7.5vw 10vh;
  }
  
  .faqs-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .faqs-list .customer-title {
    letter-spacing: 0.15rem !important;
  }
  
  .faqs-subtitle {
    text-decoration: underline;
    letter-spacing: 0.1rem;
  }
  
  .imagelist {
    display: flex;
    flex-direction: row;
    min-height: 45vh;
    align-items: center;
    margin-bottom: 10vh;
  }
  
  .imagelist-text-section {
    width: 20vw;
    padding: 0 4% 0;
    text-align: end;
  }
  
  .imagelist-title {
    letter-spacing: 0.2rem;
  }
  
  .imagelist-description {
    letter-spacing: 0.1rem;
    line-height: 1.75;
  }
  
  .imagelist-image-section {
    width: 80vw;
    height: 40vh;
    display: flex;
    flex-direction: row;
  }
  
  .imagelist-image:nth-child(2n-1) {
    margin: 0 2% 0 0;
  }
  
  .imagelist-image {
    height: auto;
    border-radius: 50px;
  }
  
  .scroll-to-top-btn {
    position: fixed; 
     left: 90%;
     bottom: 40px;
     height: 50px;
     font-size: 7rem;
     z-index: 1;
     cursor: pointer;
     color: #F4BB38;
  }
  
  @media(max-width:1024px) {
    .product-list {
      padding: 0 18%;
    }
  }
  
  @media(max-width:767px) {
    .product-list {
      padding: 0 15%;
    }
  }
  
  @media(max-width:480px) {
    .product-list {
      padding: 0 5%;
    }

    .scroll-to-top-btn {
      position: fixed; 
       left: 85%;
       bottom: 40px;
       height: 50px;
       font-size: 2rem;
    }
  }