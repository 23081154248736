.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: local("Helvetica Neue Bold"), url("./styles/fonts/HelveticaNeue/HelveticaNeue-Bold-02.ttf") format('woff');
}

@font-face {
  font-family: "HelveticaNeueMedium";
  src: local("Helvetica Neue Medium"), url("./styles/fonts/HelveticaNeue/HelveticaNeue-Medium-11.ttf") format('woff');
}

@font-face {
  font-family: "HelveticaNeueRegular";
  src: local("Helvetica Neue Regular"), url("./styles/fonts/HelveticaNeue/HelveticaNeue-01.ttf") format('woff');
}

@font-face {
  font-family: "HelveticaNeueLight";
  src: local("Helvetica Neue Light"), url("./styles/fonts/HelveticaNeue/HelveticaNeue-Light-08.ttf") format('woff');
}

body {
  font-size: 16px;
  font-family: "HelveticaNeueRegular";
  max-width: 1920px;
  margin: auto;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #2D3138;
}

::-webkit-scrollbar-thumb {
  background: #21242D;
  border-radius: 10px;
}

a,
a:active {
  color: transparent;
  text-decoration: none ;
}

.text-white {
  color: #FFFFFF;
}

.text-yellow {
  color: #F0B543;
}

.text-red {
  color: #B51A29;
}

.text-gray {
  color: #707070;
}

.fontfamily-regular {
  font-family: "HelveticaNeueRegular";
}

.fontfamily-light {
  font-family: "HelveticaNeueLight";
}

.fontsize-xl {
  font-size: 1.5rem;
}

.fontsize-l {
  font-size: 1.4rem;
}

.fontsize-m {
  font-size: 1.2rem;
}

.fontsize-s {
  font-size: 1.1rem;
}

.fontsize-xs {
  font-size: 1rem;
}

.iconsize-l {
  font-size: 3rem;
}

.iconsize-m {
  font-size: 2.5rem;
}

.iconsize-s {
  font-size: 3rem;
}

p,
span {
  font-size: 1.1rem;
}

@media(max-width: 1300px) {
  .fontsize-xl {
    font-size: 1.4rem;
  }

  .fontsize-l {
    font-size: 1.25rem;
  }

  .fontsize-m {
    font-size: 1.1rem;
  }

  .fontsize-s {
    font-size: 1rem;
  }

  .fontsize-xs {
    font-size: 0.8rem;
  }

  .iconsize-l {
    font-size: 3.5rem;
  }

  .iconsize-m {
    font-size: 3rem;
  }

  .iconsize-s {
    font-size: 3rem;
  }

  p,
  span {
    font-size: 1.1rem;
  }
}

@media(max-width: 1050px) {
  .fontsize-xl {
    font-size: 1.3rem;
  }

  .fontsize-l {
    font-size: 1.2rem;
  }

  .fontsize-m {
    font-size: 1rem;
  }

  .fontsize-s {
    font-size: 0.9rem;
  }

  .fontsize-xs {
    font-size: 0.7rem;
  }

  .iconsize-l {
    font-size: 3rem;
  }

  .iconsize-m {
    font-size: 2.5rem;
  }

  .iconsize-s {
    font-size: 2.5rem;
  }

  p,
  span {
    font-size: 1rem;
  }
}

@media(max-width: 767px) {
  .fontsize-xl {
    font-size: 1.1rem;
  }

  .fontsize-l {
    font-size: 1rem;
  }

  .fontsize-m {
    font-size: 0.95rem;
  }

  .fontsize-s {
    font-size: 0.8rem;
  }

  .fontsize-xs {
    font-size: 0.8rem;
  }

  .iconsize-l {
    font-size: 2.5rem;
  }

  .iconsize-m {
    font-size: 1.5rem;
  }

  .iconsize-s {
    font-size: 2rem;
  }

  p,
  span {
    font-size: 0.95rem;
  }
}

@media(max-width: 480px) {
  .fontsize-xl {
    font-size: 1rem;
  }

  .fontsize-l {
    font-size: 0.9rem;
  }

  .fontsize-m {
    font-size: 0.8rem;
  }

  .fontsize-s {
    font-size: 0.7rem;
  }

  .fontsize-xs {
    font-size: 0.7rem;
  }

  .iconsize-l {
    font-size: 2rem;
  }

  .iconsize-m {
    font-size: 1rem;
  }

  .iconsize-s {
    font-size: 1rem;
  }

  p,
  span {
    font-size: 0.9rem;
  }
}