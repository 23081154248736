.rdprogram-main {
  max-width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 90px 0;
  box-sizing: border-box;
  background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.program-img {
  width: 95vw;
}

.btn-group {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 16px;
}

.btn-language {
  border: 1px solid #B7B7B7;
  background-color: transparent;
  padding: 8px 32px;
  border-radius: 26px;
  letter-spacing: 0.1rem;
  font-family: "HelveticaNeueMedium";
  font-size: 1.1rem;
  color: #B7B7B7;
}

@media(max-width: 768px) {
  .btn-language {
    font-size: 0.8rem;
    padding: 8px 16px;
  }
}