.partners-background {
  max-width: 100vw;
  min-height: 100vh;
  background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
}

.partners-content {
  padding: 90px 0;
  margin: 0 5vw 0;
}

.features-section {
  margin: 5vh auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners-title {
  color: #fff;
  border-bottom-color: #F0B543;
  border-bottom-width: 2px;
  letter-spacing: 4.8px;
  width: fit-content;
  border-bottom-style: solid;
  margin-bottom: 2rem;
  font-family: "HelveticaNeueRegular";
}

.features-title {
  color: #B7B7B7;
  padding: 1rem 3rem;
  border-radius: 18px;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 3.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.info-title {
  font-family: "HelveticaNeueBold";
  letter-spacing: 4px;
  text-align: center;
  color: #fff;
}

.info-title-sales {
  font-family: "HelveticaNeueBold";
  letter-spacing: 4px;
  text-align: center;
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 5rem;
  border-bottom-color: #F0B543;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.webinar-content {
  font-family: "HelveticaNeueRegular";
  letter-spacing: 3.6px;
  text-align: center;
  color: #fff;
}

.webinar-form-section {
  margin-top: 12rem;
}

.features-content {
  color: #B7B7B7;
  padding: 1rem 3rem;
  border-radius: 18px;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 3.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features-content .content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.features-section a {
  width: 100%;
  text-align: center;
}

.download-btn {
  border: 1px solid #B7B7B7;
  background-color: transparent;
  padding: 2% 4%;
  border-radius: 26px;
  letter-spacing: 0.1rem;
  font-family: "HelveticaNeueMedium";
  color: #B7B7B7;
  width: fit-content;
  margin: 2rem 0 0;
  /* align-self: flex-end; */
}

.download-btn,
.download-btn:focus {
  outline: none;
}

.download-btn:hover {
  background-color: #6A6A6A;
  border-color: #6A6A6A;
  color: #fff;
  font-family: "HelveticaNeueMedium";
}

.info-card-list {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  width: 100%;
  justify-content: flex-start;
  margin: 15vh 0 30vh;
}

.info-card {
  padding: 2rem 3em;
  box-sizing: border-box;
  /* outline: #F0B543 2px solid; */
  border-radius: 18px;
  /* flex: 1; */
  width: 26%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* .info-card:hover {
  outline: #F0B543 5px solid;
} */

.info-icon {
  padding: 5% 10% 0;
  box-sizing: border-box;
}

.info-icon .icon {
  /* height: 175px; */
  width: 100%;
  height: auto;
}

.webinar-form-web .left-section {
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 7vh;
  justify-self: center;
  align-items: center;
}

.salesslicks-form-web .left-section {
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-self: center;
  align-items: center;
}

.salesslicks-form-mobile .left-section {
  display: none;
}

.salesslick-content {
  font-family: "HelveticaNeueRegular";
  letter-spacing: 3.6px;
  text-align: left;
  color: #ffffff;
  margin-top: 1rem;
}

.salesslick-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.webinar-icon {
  width: 100%;
}

.webinar-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.webinar-form-web {
  /* display: grid; */
  grid-template-columns: repeat(2, calc(50% - 5vw));
  grid-template-rows: auto auto;
  grid-gap: 0 10vw;
  /* margin: 10vh 0 0; */
}

.salesslicks-form-web {
  /* display: grid; */
  grid-template-columns: repeat(2, calc(50% - 5vw));
  grid-template-rows: auto auto;
  grid-gap: 0 10vw;
  margin: 10vh 0 0;
}

.salesslicks-form-mobile {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5vw));
  grid-template-rows: auto auto;
  grid-gap: 0 10vw;
  margin: 10vh 0 0;
}

.webinar-form-mobile {
  /* display: grid; */
  grid-template-columns: repeat(2, calc(50% - 5vw));
  grid-template-rows: auto auto;
  grid-gap: 0 10vw;
  margin-bottom: 4rem;
}


.webinar-hint-text {
  line-height: 1.5;
  margin: 0 0 2rem;
  width: 90%;
  grid-column: 1/3;
  font-family: "HelveticaNeueLight";
  color: #fff;
}

.webinar-hint-text,
.webinar-sub-title {
  letter-spacing: 0.05rem;
}

.webinar-form-list-web {
  display: grid;
  /* grid-template-columns: repeat(2, calc(50% - 1rem)); */
  grid-gap: 2rem;
}

.right-section .webinar-form-list-web {
  /* grid-template-rows: repeat(2, 6rem); */
}

.webinar-form-list-mobile {
  display: grid;
  grid-template-columns: repeat(1, calc(100% - 1rem));
  grid-gap: 4rem;
  /* grid-template-rows: repeat(4, 20%); */
}

.webinar-form-group,
.webinar-form-additional {
  display: flex;
  flex-direction: column;
}

.webinar-form-additional {
  margin: 2rem 0;
}

.webinar-form-additional-mobile {
  margin: 1rem 0;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
}

/* .webinar-form-left-section {
  width: 50%;
}

.webinar-form-right-section {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0.25rem 0;
} */

.webinar-form-title {
  margin: 0.5rem 0;
  letter-spacing: 0.1rem;
}

.webinar-form-title,
.webinar-form-control {
  margin: 0.5rem 0;
  font-family: "HelveticaNeueRegular";
  color: #fff;
}

.webinar-form-control {
  border-color: #707070 !important;
  border-width: 1px;
  border-radius: 20px;
  border-style: solid;
  height: fit-content;
  outline: none;
  padding: 0.5rem 1rem;
  width: 100%;
}

.webinar-link {
  color: deepskyblue;
  text-decoration: underline;
}

.invalid-feedback {
  font-family: "HelveticaNeueRegular";
  color: #F0B543;
  font-size: 1rem;
}

input.webinar-form-control {
  width: calc(100% - 2rem - 2px);
}

.left-section .webinar-form-control {
  background-color: #2D3037;
}

.right-section .webinar-form-control {
  background-color: #21242D;
}

.additional-form-control {
  border-color: #707070 !important;
  border-width: 1px;
  border-style: solid;
  min-height: 12vh;
  border-radius: 6px;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0;
  font-family: "HelveticaNeueRegular";
  color: #fff;
  background-color: #21242D;
}

.webinar-submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 95%;
}

.webinar-submit-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  grid-column: 1/3;
}

.webinar-submit-btn {
  background-color: #F0B543;
  border-radius: 2rem;
  border-color: transparent;
  box-shadow: 2px 2px #707070;
  padding: 0.5rem 1rem;
  margin: 2rem 0;
  letter-spacing: 0.1rem;
  width: 35%;
  font-family: "HelveticaNeueRegular";
  color: #fff;
}

.webinar-submit-btn:hover {
  background-color: #E39C12;
}

.webinar-submit-btn,
.webinar-submit-btn:focus {
  outline: none;
}

@media(max-width: 767px) {
  .salesslicks-form-web {
    display: none;
  }

  .webinar-form-group,
  .webinar-form-additional {
    /* height: 6rem; */
    max-height: 8rem;
    min-height: 0rem;
  }

  .invalid-feedback {
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }

  .webinar-form-title {
    letter-spacing: 0.05rem;
    flex: 1;
  }

  .input-group {
    flex: 2;
  }

  .notice-content {
    margin: 60px auto;
    width: 85%;
  }

  .notice-text {
    line-height: 1.5;
    padding: 1rem 2rem;
  }

  .webinar-form-web,
  .webinar-form-additional,
  .webinar-submit,
  .webinar-form-list-web {
    display: none;
  }

  .webinar-form-mobile,
  .webinar-form-list-mobile {
    display: grid;
  }

  .webinar-form-additional-mobile,
  .webinar-submit-mobile {
    display: flex;
  }

  .webinar-form-mobile .left-section,
  .webinar-form-mobile .right-section {
    grid-column: 1/3;
  }

  .webinar-form-mobile {
    gap: 5rem 10vw;
  }

  .contact-section-web {
    grid-gap: 15% 10%;
    grid-template-columns: 45% 45%;
    grid-template-rows: 1fr 1fr;
    align-items: start;
    margin-bottom: 6rem;
  }

  .contact-list:first-child {
    grid-column: 1/3;
  }

  .features-section {
    width: 100%;
  }

  .features-content {
    padding: 1rem 1rem;
  }

  .info-card-list {
    flex-direction: column;
    margin: 8vh 0;
  }

  .info-card {
    width: 100%;
  }

  .webinar-icon-section {
    width: 80%;
    margin: auto;
  }

  
  .salesslicks-form-mobile {
    display: flex;
  }

  .salesslicks-form-mobile .left-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-self: center;
    align-items: center;
  }
}

@media(max-width: 480px) {
  .support-content {
    padding: 90px 0 0;
    margin: 0 5vw 0;
  }

  .support-title {
    margin-bottom: 1rem;
  }

  .notice-content {
    width: 100%;
  }

  .notice-text {
    padding: 1rem 1.5rem;
  }

  .invalid-feedback {
    font-size: 0.8rem;
  }

  .webinar-form-group,
  .webinar-form-additional {
    /* height: 6rem; */
    max-height: 8rem;
    min-height: 0rem;
  }

  .webinar-form-title,
  .webinar-form-control {
    margin: 0.25rem 0;
  }

  .webinar-form-additional {
    margin: 1rem 0;
  }

  .webinar-webinar-btn {
    margin: 0 0 1rem;
  }

  .contact-section-mobile {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 0.75fr 0.75fr;
    align-items: center;
    margin-bottom: 6rem;
  }

  .contact-list:first-child {
    grid-column: 1/2;
  }
}

@media(min-width: 767px) {

  .webinar-form-web,
  .webinar-form-list-web,
  .salesslicks-form-web {
    display: grid;
  }

  .webinar-form-additional,
  .webinar-submit {
    display: flex;
  }

  .webinar-form-mobile,
  .webinar-form-additional-mobile,
  .webinar-submit-mobile,
  .webinar-form-list-mobile {
    display: none;
  }
}