.partners-background {
  max-width: 100vw;
  min-height: 100vh;
  background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
}

.partners-content {
  padding: 90px 0;
  margin: 0 5vw 0;
}

.features-section {
  margin: 5vh auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features-title {
  color: #B7B7B7;
  padding: 1rem 3rem;
  border-radius: 18px;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 3.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.features-content {
  color: #B7B7B7;
  padding: 1rem 3rem;
  border-radius: 18px;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 3.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features-content .content {
  display: flex;
  flex-direction: column;
}

.features-section a {
  width: 100%;
  text-align: center;
}

@media(max-width: 767px) {
  .features-section {
    width: 100%;
  }

  .features-content {
    padding: 1rem 1rem;
  }
}