.customers-background {
  width: 100%;
  /* height: calc(100vh - 8.5rem); */
  background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
  /* height: calc(100% - 90px); */
  height: calc(100vh - 2px - 128px);
  padding: 90px 0 0;
  box-sizing: border-box;
}

.customers-background-mobile {
  width: 100%;
  background: linear-gradient(to right, #21242D 50%, #2D3138 50%);
  padding: 90px 0 0;
}

.customers-content-web {
  padding: 3rem 5vw 3rem;
  box-sizing: border-box;
  height: calc(100% - 0rem);
  /* display: grid; */
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(2, 45%);
  grid-template-rows: repeat(2, 43%);
  grid-gap: 14% 10%;
}

.customers-content-mobile {
  padding: 4rem 0;
  margin: 0 5vw 0;
  /* display: grid; */
  justify-items: center;
  grid-template-columns: 100%;
  grid-template-rows: repeat(4, 19%);
  grid-gap: 8%;
}

.customers-link {
  text-align: center;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.link-image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  outline: 2px solid #F0B543;
  border-radius: 33px;
}

.link-image:hover {
  outline: 6px solid #F0B543;
}

@media(max-width: 767px) {

  .customers-background,
  .customers-content-web {
    display: none;
  }

  .customers-background-mobile {
    display: block;
  }

  .customers-content-mobile {
    display: grid;
  }

  /* .customers-background {
    height: calc(100vh - 4rem - 5rem);
  } */

  .customers-content {
    padding: 11rem 0;
  }

  .link-image {
    border-radius: 16px;
  }
}

@media(max-width: 480px) {
  .customers-content {
    padding: 7rem 0;
  }
}

@media(min-width: 767px) {

  .customers-background-mobile,
  .customers-content-mobile {
    display: none;
  }

  .customers-background {
    display: block;
  }

  .customers-content-web {
    display: grid;
  }
}